import { Suspense, lazy, useMemo } from 'react'
import './App.css'
import { useAuthContext } from './hooks/useAuthContext'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'
import ScrollToTop from './components/ScrollToTop'
import { retry } from './utils/CommonFunctions'
import { SnackbarProvider } from 'notistack'
import Loader from './components/Loader'
import { Navigate, Route, Routes } from 'react-router-dom'
import { styled } from '@mui/material'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #dd6b63;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const SideBar = lazy(() => retry(() => import('./layout/SideBar')))
const Login = lazy(() => retry(() => import('./pages/Login')))
const FourOhFour = lazy(() => retry(() => import('./pages/FourOhFour')))
const PrivacyPolicy = lazy(() => retry(() => import('./pages/PrivacyPolicy')))
const Support = lazy(() => retry(() => import('./pages/Support')))
const DeleteAccount = lazy(() => retry(() => import('./pages/DeleteAccount')))

function App() {
  const { token } = useAuthContext()

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#DD6B63',
          },
          secondary: {
            main: '#D8D8D8',
          },
          error: {
            main: '#E11A1A',
          },
        },
        typography: {
          fontFamily: 'Inter, sans-serif',
          h4: {
            fontFamily: 'Playfair, serif',
          },
          h6: {
            fontFamily: 'Playfair, serif',
          },
          subtitle2: {
            color: '#D8D8D8',
          },
          body2: {
            color: '#999999',
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: '30px',
                height: '55px',
                boxShadow: 'none',
                fontFamily: 'Playfair, serif',
                fontSize: '18px',
                fontWeight: 600,
                minWidth: '115px',
                padding: '6px 25px',
                ':hover': {
                  boxShadow: 'none',
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              input: {
                borderRadius: '8px !important',
                fontSize: '14px',
              },
              notchedOutline: {
                borderColor: '#CFD3D4',
                borderWidth: '1.5px',
                borderRadius: '8px',
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                fontSize: '12px',
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                border: '1px solid #0000000F',
                boxShadow: '0px 0px 8px 0px #0000000D',
                borderRadius: '16px',
                padding: '18px',
                gap: '10px',
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                padding: 0,
                ':last-child': {
                  paddingBottom: 0,
                },
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: '#FFFFFF',
                color: '#000',
                border: '1px solid rgba(234, 236, 238, 0.6)',
                boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.04)',
              },
              arrow: {
                color: '#FFFFFF',
                '::before': {
                  border: '1px solid rgba(234, 236, 238, 0.6)',
                },
              },
            },
          },
        },
      }),
    [],
  )

  return (
    <>
      <ScrollToTop />
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <StyledSnackbarProvider
          hideIconVariant
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path="/admin/*"
                element={
                  token ? <SideBar /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/login"
                element={
                  !token ? (
                    <Login />
                  ) : (
                    <Navigate replace to="/admin/dashboard" />
                  )
                }
              />
              <Route
                exact
                path="/"
                element={<Navigate replace to="/admin/login" />}
              />
              <Route
                exact
                path="/admin"
                element={<Navigate replace to="/admin/login" />}
              />
              <Route exact path="/*" element={<Navigate replace to="/404" />} />
              <Route exact path="/404" element={<FourOhFour />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/support" element={<Support />} />
              <Route exact path="/delete-account" element={<DeleteAccount />} />
            </Routes>
          </Suspense>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </>
  )
}

export default App
